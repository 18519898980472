import React from 'react'

const CalendarCheckGreen: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_9523_263272)">
        <path
          d="M35.5506 8.41486C35.6774 6.15393 33.8662 4.25259 31.5841 4.25259H29.8318V2.25237C29.8318 1.1646 28.9443 0.283605 27.8485 0.283605C26.7527 0.283605 25.8653 1.1646 25.8653 2.25237V4.25259H9.96307V2.23439C9.96307 1.14662 9.07559 0.265625 7.97982 0.265625C6.88405 0.265625 5.99657 1.14662 5.99657 2.23439V4.25259H4.24878C1.96216 4.25709 0.150974 6.15842 0.277757 8.41486L0.377372 10.1589H35.4555L35.5551 8.41486H35.5506Z"
          fill="#00E100"
        />
        <path
          d="M39.7334 25.9225C39.7334 24.8707 39.3169 23.8774 38.5698 23.1357C37.8181 22.394 36.822 21.985 35.7624 21.985C35.4274 21.985 35.1013 22.0389 34.7844 22.1199L35.2326 14.1055H0.59375L1.82083 36.0136C1.93855 38.1037 3.67729 39.7353 5.78732 39.7353H30.0436C32.1491 39.7353 33.8924 38.0992 34.0101 36.0136L34.1731 33.0874L38.5743 28.7139C39.3259 27.9677 39.738 26.9788 39.738 25.927L39.7334 25.9225Z"
          fill="white"
        />
        <path
          d="M39.7334 25.9225C39.7334 24.8707 39.3169 23.8774 38.5698 23.1357C37.8181 22.394 36.822 21.985 35.7624 21.985C35.4274 21.985 35.1013 22.0389 34.7844 22.1199L35.2326 14.1055H0.59375L1.82083 36.0136C1.93855 38.1037 3.67729 39.7353 5.78732 39.7353H30.0436C32.1491 39.7353 33.8924 38.0992 34.0101 36.0136L34.1731 33.0874L38.5743 28.7139C39.3259 27.9677 39.738 26.9788 39.738 25.927L39.7334 25.9225ZM7.9698 35.7843C6.87403 35.7843 5.98655 34.9033 5.98655 33.8156C5.98655 32.7278 6.87403 31.8468 7.9698 31.8468C9.06556 31.8468 9.95304 32.7278 9.95304 33.8156C9.95304 34.9033 9.06556 35.7843 7.9698 35.7843ZM7.9698 29.878C6.87403 29.878 5.98655 28.997 5.98655 27.9093C5.98655 26.8215 6.87403 25.9405 7.9698 25.9405C9.06556 25.9405 9.95304 26.8215 9.95304 27.9093C9.95304 28.997 9.06556 29.878 7.9698 29.878ZM13.9331 35.7978C12.8374 35.7978 11.9499 34.9168 11.9499 33.829C11.9499 32.7413 12.8374 31.8603 13.9331 31.8603C15.0289 31.8603 15.9164 32.7413 15.9164 33.829C15.9164 34.9168 15.0289 35.7978 13.9331 35.7978ZM37.1706 27.3204L29.224 35.218C28.8527 35.5865 28.3456 35.7978 27.8204 35.7978C27.2951 35.7978 26.788 35.5865 26.4167 35.218L22.4502 31.258C21.6759 30.4848 21.6805 29.2398 22.4593 28.4711C22.8441 28.0891 23.3513 27.8958 23.8584 27.8958C24.3655 27.8958 24.8772 28.0891 25.2666 28.4756L27.8294 31.0332L34.3678 24.5336C34.7572 24.147 35.2643 23.9538 35.7715 23.9538C36.2786 23.9538 36.7857 24.147 37.1751 24.5291C37.9539 25.2977 37.9539 26.5473 37.1751 27.3159L37.1706 27.3204Z"
          fill="#00E100"
        />
      </g>
      <defs>
        <clipPath id="clip0_9523_263272">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CalendarCheckGreen
